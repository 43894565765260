import React, {Component, Fragment} from 'react';
import ShoppingCartIcon from "components/ShoppingCartIcon";
import CompareItemIcon from "components/CompareItemIcon";
import Notifications from "components/Notifications";
// import "components/CatalogueHeader.css";
import axios from "axios1";
import $ from "jquery";
import html2canvas from 'html2canvas';
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, NavLink, withRouter} from "react-router-dom";
import LoginBtn from 'components/Login/LoginBtn';
import LogoutBtn from 'components/Login/LogoutBtn';
import ClearBtn from 'components/ClearBtn';
import MobileNav from 'components/MobileNav';
import {GridLoader} from 'react-spinners';
import "admin/administrator/CustomElements.css";
import AlertSessionTimeOutModal from 'components/catalogue/AlertSessionTimeOutModal';
import CaptureScreenshot from "components/CaptureScreenshot";
import LogoObject from "components/clients/MainLogo.js";
import {updateOutstandingOrdersQty} from "redux/actions/shoppingCart";
import TakeScreenshot from "components/TakeScreenshot";
import Icon from 'components/Icon';
import Loading from "admin/administrator/LoadingComponent/Loading.component";

var store = require('store');

class CatalogueHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            styles: [],
            outstandingOrdersCount: 0,
            showCaptureModal: false
        };

        this.getSections();
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.countOutstandingOrdersQty();
        }, 60 * 1000);
    }

    getSections() {
        axios.get("/catalogue/logo")
            .then((response) => {
                this.setState({styles: response.data.styles});
            });
    }

    countOutstandingOrdersQty() {
        axios.get("/catalogue/account/orders/outstanding-orders-qty")
            .then((response) => {
                this.props.dispatch(updateOutstandingOrdersQty(response.data));
            })
    }

    // isClickableCartIcon = (e) => {
    //     const products = this.props.products;
    //     if (products.length === 0 && this.props.auth.token) e.preventDefault()
    // }

    isOnDashboard = () => {
        return window.location.toString().includes("admin");
    }

    isOnMyOrders = () => {
        return window.location.toString().includes("orderHistory");
    }

    isClickableCompareIcon = (e) => {
        const compareList = this.props.compareList;
        if (compareList.length === 0 && this.props.auth.token) e.preventDefault()
    }

    captureScreenshot() {
        this.setState({showCaptureModal: !this.state.showCaptureModal}, () => {
            let options = {
                logging: true,
                useCORS: true,
                allowTaint: true
            }
            html2canvas(document.body, options).then(function (canvas) {
                let dataURL = canvas.toDataURL();
                canvas.setAttribute("style", "width: 100%");
                document.getElementById("screenshot-wrapper").appendChild(canvas);
            });
        });
    }

    setStateFromChild(key, value) {
        let state = {};
        state[key] = value;
        this.setState(state);
    }

    render() {
        let user = this.props.auth.user;
        let isOnMyOrders = this.isOnMyOrders();
        //let loading = false;
        let button;
        $("body").removeAttr('class');
        $("body").addClass(this.props.settings.theme);
        button = this.props.auth.token ? <LogoutBtn/> : <LoginBtn/>;
        return (
            <Fragment>
                <CaptureScreenshot showCaptureModal={this.state.showCaptureModal} setStateFromChild={this.setStateFromChild.bind(this)}/>
                <AlertSessionTimeOutModal></AlertSessionTimeOutModal>
                <Loading loading={this.state.loading}/>
                {
                    store.get('token') ? 
                    <header className="header">
                        <div className="logo">
                            <NavLink to={"/search"} className="logo__link">
                                <img style={{width: LogoObject.size}} src={LogoObject.logo} className="logo__image"/>
                            </NavLink>
                        </div>
                        <div className="nav">
                            <ul className="nav__list">
                                {
                                    (user && (user.admin || user.account.business_type_id == 3)) &&
                                        <li className="nav__item">
                                            <NavLink className="nav__link"
                                                    to={user.account.business_type_id == 3 && !user.admin ? "/admin/branch/" + user.account.id + "/" + user.account.name + "/dashboard/" : "/admin/client/dashboard"}
                                                    activeClassName="nav__link--active"
                                                    isActive={(match, location) => {
                                                        if(location.pathname.indexOf("/admin/") > -1) {
                                                            return true;
                                                        }
                                                    }}>
                                                <div className="nav__icon">
                                                    <Icon file="dashboard.svg" type="dark" width="24px" height="24px"/>
                                                </div>
                                                <span className="nav__text">Dashboard</span>
                                            </NavLink>
                                        </li>
                                }
                                <li className="nav__item">
                                    <NavLink className="nav__link" to="/search" activeClassName="nav__link--active">
                                        <div className="nav__icon">
                                            <Icon file="book.svg" type="dark" width="24px" height="24px"/>
                                        </div>
                                        <span className="nav__text">Catalogue</span>
                                    </NavLink>
                                </li>
                                <li className="nav__item nav__item--account">
                                    <NavLink to="/account"
                                            data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                                            aria-expanded="false"
                                            activeClassName="nav__link--active"
                                            className="nav__link">
                                        <div className="nav__icon">
                                            <Icon file="User.svg" type="dark" width="24px" height="24px"/>
                                            {
                                                user ?
                                                    <div className={"badge badge--danger"}>
                                                        {
                                                            (parseInt(this.props.ordersQty) + parseInt(this.props.customerOrdersQty)) > 99 ? 
                                                                "99+" : parseInt(this.props.ordersQty) + parseInt(this.props.customerOrdersQty)
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <span className="nav__text">My Account</span>
                                    </NavLink>
                                    <div className="dropdown-menu">
                                        <div className="profile">
                                            <div className="profile__name">{(user) ? user.name : ""}</div>
                                            <div className="profile__email">{(user) ? user.email : ""}</div>
                                            <div className="profile__version">V{process.env.REACT_APP_VERSION}</div>
                                        </div>
                                        <div className="subnav">
                                            <ul className="subnav__list">
                                                <li className="subnav__item">
                                                    {
                                                        user && user.admin ?
                                                            <NavLink to="/account/orders" className="subnav__link">
                                                                Orders
                                                                {
                                                                    user && user.admin ?
                                                                            <div className={"badge badge--danger"}>
                                                                                {
                                                                                    this.props.ordersQty > 99 ? "99+" : this.props.ordersQty
                                                                                }
                                                                            </div>
                                                                            : null
                                                                }
                                                            </NavLink> : null
                                                    }
                                                    {
                                                        user && !user.admin ?
                                                            <NavLink to="/account/my-orders" className="subnav__link">
                                                                My Orders
                                                                <div className={"badge badge--danger"}>
                                                                    {
                                                                        this.props.ordersQty > 99 ? "99+" : this.props.ordersQty
                                                                    }
                                                                </div>
                                                            </NavLink> : null
                                                    }
                                                </li>
                                                <li className="subnav__item">
                                                    {
                                                        user && user.account.business_type_id == 3 ?
                                                            <NavLink to="/account/customer-orders" className="subnav__link">
                                                                Customer Orders
                                                                <div className={"badge badge--danger"}>
                                                                    {
                                                                        this.props.customerOrdersQty > 99 ? "99+" : this.props.customerOrdersQty
                                                                    }
                                                                </div>
                                                            </NavLink> : null
                                                    }
                                                </li>
                                                <li className="subnav__item">
                                                    {
                                                        (user && process.env.REACT_APP_CLIENT === 'nk') &&
                                                            <NavLink to="/account/return-form" className="subnav__link">
                                                                Product Return Form
                                                            </NavLink>
                                                    }
                                                </li>
                                                <li className="subnav__item">
                                                    {
                                                        this.props.auth.previousUser ? 
                                                            <ClearBtn  size={{width: '100%'}} />
                                                        :
                                                            <LogoutBtn size={{width: '100%'}} setStateFromChild={this.setStateFromChild.bind(this)}/>
                                                    }                                                
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav__item">
                                    <NavLink to="/compareItems" className="nav__link" activeClassName="nav__link--active">
                                        <CompareItemIcon/>
                                        <span className="nav__text">Compare</span>
                                    </NavLink>
                                </li>
                                <li className="nav__item">
                                    {
                                        this.props.settings.enable_shopping_cart ?
                                        <NavLink to="/Checkout" className="nav__link" activeClassName="nav__link--active">
                                            <ShoppingCartIcon/>
                                            <span className="nav__text">Basket</span>
                                        </NavLink> : null
                                    }
                                </li>
                            </ul>
                        </div>
                    </header> : null
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    compareList: state.compareList,
    products: state.shoppingCart,
    auth: state.auth,
    settings: state.settings,
    ordersQty: state.orders.ordersQty,
    customerOrdersQty: state.orders.customerOrdersQty
})

export default withRouter(connect(mapStateToProps, null, null, {pure: false})(CatalogueHeader));
