import React from "react";
import Layout from "account/Orders/ReturnForm/Components/Layout";
import _ from "lodash";
import "account/Orders/ReturnForm/Forms/ReturnFormPDF.css";

const ReturnFormCorePdf =  React.forwardRef((props, ref) => {
    const tableRowCount = 7;
    let date = new Date().toLocaleString({timeZone: 'Europe/London'}, {
        month:"2-digit", day: "numeric", year: "numeric", hour: "2-digit", minute: "2-digit"
    });
    return (
        <Layout ref={ref}>
            <div className={`pdf-section`}>
                <div className="pdf-card">
                    <div className="pdf-mg-bottom-25">{date}</div>

                    <div className="pdf-card-top pdf-mg-bottom-25">
                        <div className="pdf-flex-centred">
                            <h3>RETURN</h3>
                        </div>
                    </div>
                    <div className="pdf-card-body">
                        <div className="pdf-form-input"><p className="pdf-font-size-sm">( tick the appropriate! )</p></div>
                        <div className="pdf-form-input pdf-flex"><p>1. Night express</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                        <div className="pdf-form-input pdf-flex"><p>2. Carrier</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                        <div className="pdf-form-input pdf-flex"><p>3. Own choice (ie. UPS/TNT, Postal service)</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                    </div>
                    <div className="pdf-card-footer pdf-flex">
                        <h4>Recipient:</h4>
                        <ul>
                            <li>SBS</li>
                            <li>Deutschland GmbH</li>
                            <li>Abteilung Logistik</li>
                            <li>Eichrodter Weg 57</li>
                            <li>99817 Eisenach</li>
                        </ul>
                    </div>
                </div>
                <div className="pdf-card">
                    <p className="pdf-mg-bottom-25">Pfand</p>
                    <div className="pdf-flex">
                        <div className="pdf-card-left">
                            <div className="pdf-form-input pdf-flex"><p className="pdf-bold">Reference</p> <input type="text"/> </div>
                            <div className="pdf-form-input pdf-flex"><p className="pdf-bold">Date</p> <input type="text" value={date}/> </div>
                            <div className="pdf-form-input pdf-flex"><p className="pdf-bold">Customer number</p> <input type="text" value={props.account?.account_number}/> </div>
                        </div>
                        <div className="pdf-card-right">
                            <img src="https://nk.availcat.com/static/media/nk-logo.762d9ec0.png" width={123} height={80}></img>
                        </div>
                    </div>
                    <div className="pdf-card-address-wrapper">
                        <h5>Dispatched by/ Company stamp</h5>
                        <div className="pdf-card-address-body">                            
                            <p>{props.account?.address_1}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pdf-section pdf-mg-top">
                <div className="pdf-card">
                    <table className="pdf-table">
                        <tr>
                            <th style={{width:"15%"}}>Code</th>
                            <th style={{width:"15%"}}>Qty</th>
                            <th style={{width:"35%"}}>Item reference</th>
                            <th style={{width:"35%"}}>Number of packages</th>
                        </tr>
                        {
                            _.times(tableRowCount, () => {
                                return (
                                    <tr>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                    </tr>
                                );
                            })
                        }
                    </table>
                </div>
                <div className="pdf-card">
                    <table className="pdf-table">
                        <tr>
                            <th style={{width:"15%"}}>Code</th>
                            <th style={{width:"15%"}}>Qty</th>
                            <th style={{width:"35%"}}>Item reference</th>
                            <th style={{width:"35%"}}>Number of packages</th>
                        </tr>
                        {
                            _.times(tableRowCount, () => {
                                return (
                                    <tr>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                    </tr>
                                );
                            })
                        }
                    </table>
                </div>
            </div>

            <div className="pdf-section pdf-mg-top">
                <div className="pdf-card pdf-mg-top">
                    <h2>Can only be used for Core return</h2>

                    <div className="pdf-list-warpper pdf-mg-top pdf-mg-bottom-25">
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">Number of packages</p><input style={{height: '30px'}} type="text"/></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">Weight</p><input style={{height: '30px'}} type="text"/></div>
                    </div>

                    <div className="pdf-reason-desc pdf-mg-top">
                        <p>Stock return must be agreed upon before return!</p>
                        <p>Cores must be returned in its original box</p>
                        <p>For returns without signing the shipper shall bear the costs!</p>
                    </div>

                    <a href="https://www.sbs-shop.de/formulare.php" className="pdf-site-link">https://www.sbs-shop.de/formulare.php</a>
                </div>
                <div className="pdf-card">
                    <div className="pdf-returnprocessing-wrapper pdf-mg-top">
                            <div className="pdf-form-input pdf-flex"><p>Shipment recieved on:</p> <div className="pdf-fake-input"></div></div>
                            <div className="pdf-form-input pdf-flex "><p>Processed on</p> <div className="pdf-fake-input"></div> </div>

                            <div className="pdf-mg-top">
                                <div className="pdf-form-input pdf-flex"><p>Finished processing</p> <span className="pdf-underline"></span> </div>
                                <div className="pdf-form-input pdf-flex"><p>Date</p> <span className="pdf-underline"></span> </div>
                                <div className="pdf-form-input pdf-flex"><p>Employee</p> <span className="pdf-underline"></span> </div>
                            </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
});

export default ReturnFormCorePdf;