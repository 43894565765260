import React, {Fragment, useState, useEffect} from 'react';
import ChooseFile from "components/ChooseFile";
import Modal from "components/Modal";
import CustomButton from "admin/administrator/CustomButton.js";
import CustomCheckBox from "admin/administrator/CustomCheckBox";
import { setCart } from "redux/actions/authActions";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios1";
import XLSX from 'xlsx';
import alertify from "alertifyjs";
import Pagination from "components/Pagination";
import FormInput from "admin/administrator/FormInput/FormInput.component";

const OrderUpload = props => {

    const [showModal, setShowModal] = useState(false);
    const [issuesList, setIssuesList] = useState([]);
    const [filteredIssueList, setFilteredIssueList] = useState([]);
    const [search, setSearch] = useState(null);

    const [currentIssueList, setCurrentIssueList] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const [totalPages, setTotalPages] = useState(null);

    const errors = {
        404: "Unrecognized part number",
        quantity: (quantity, asked_quantity) => {
            return `Ordered ${asked_quantity}, only ${quantity} available`
        },
    };

    useEffect(() => {
        redrawPagination(filteredIssueList.length, currentPage);
    }, [filteredIssueList]);

    const handleFile = e => {
        let files = e.target.files, f = files[0];
        if (f !== undefined) {

            let regex = new RegExp("(.*?)\.(xls|xlsx|csv)$");
            let reader = new FileReader();
            if (!(regex.test(f.name.toLowerCase()))) {
                alertify.error("Supported only (xls, xlsx, csv) type files!");
                return;
            }

            reader.onload = (e) => {
                let result = new Uint8Array(e.target.result);
                let workbook = XLSX.read(result, {type: 'array'});
                let sheetNames = workbook.SheetNames[0];
                let sheets = workbook.Sheets[sheetNames];
                let orders = XLSX.utils.sheet_to_json(sheets, {header: 1});
                orders.splice(0, 1);
                // setPartNumbers(partNumbers);
                checkOrderData(orders);
            };

            reader.readAsArrayBuffer(f);
        }
    }

    const checkOrderData = orders => {
        props.setLoading(true);

        const dataForCheck = _.map(orders, item => {
            let newItem = {
                part_number: item[0],
                quantity: item[1],
            };
            return newItem;
        });

        axios.post("/catalogue/check_quantity", {
            data: [ ...dataForCheck ],
        }).then( response => {

            if(response.status === 200 ) {
                clearOrderUpload();
                props.setCart();
                props.setLoading(false);
                alertify.success(response.data.message);
            }
            
        }).catch( error => {          
            if(error.response.status === 422 ) {
                const responseProducts = error.response.data.data;
                setIssuesList(responseProducts);
                setFilteredIssueList(responseProducts)
                setShowModal(true);
                
            }else {
                props.setShowWarning(true);
                props.setWarningMsg(error.response.data.message);
            }
            props.setLoading(false);
          })
    }

    const clearOrderUpload = () => {
        setShowModal(false);
        setIssuesList([]);
        setFilteredIssueList([]);
        setSearch([]);
    }

    const makeOrder = () => {
        props.setLoading(true);
        const orders = _.filter(issuesList, {approved: true});
        axios.post("/catalogue/add_many_to_cart", {
            data: [...orders],
        }).then( response => {
            props.setLoading(false);
            clearOrderUpload();
            props.setCart();
            alertify.success(response.data.message);
        }).catch( error => {
            props.setShowWarning(true);
            props.setWarningMsg(error.response.data.message);
        });
    }

    const redrawPagination = (totalRecords, currentPage, pageLimit = 4) => {
        const paginationData = {
            currentPage: currentPage,
            pageLimit: pageLimit,
            totalRecords: totalRecords
        };
        onPageChanged(paginationData);
    }
   
    const onPageChanged = data => {
        const {currentPage, totalPages, pageLimit} = data;
        const offset = (currentPage - 1) * pageLimit;

        let partNumbers = filteredIssueList.slice(offset, offset + pageLimit);

        setCurrentIssueList(partNumbers);
        setCurrentPage(currentPage);
        setTotalPages(totalPages);
    };

    const handleFilterPartNumbers = e => {
        
        let filteredPartNumbers = issuesList.filter( item => {
            return (item?.part_number.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ? item : null;
        });

        setFilteredIssueList(filteredPartNumbers);
        redrawPagination(filteredPartNumbers.length, currentPage);
        setSearch(e.target.value);
    }

    const handleApproveChange = (property, event) => {

        let listOfItems = filteredIssueList;
        let item = _.find(listOfItems, {part_number: property});
        item.approved = event.target.checked;

        setFilteredIssueList([ ...listOfItems ]);
        setIssuesList(listOfItems);
    }

    return (
        <Fragment>            
            <ChooseFile handleFile={handleFile} title={[<i class="fas fa-plus"></i>, " upload order"]} />
            {
                issuesList.length > 0 &&
                    <Modal size="md" 
                        title={"Issues with order"} 
                        type="light" 
                        active={showModal}
                        scrollable={true}
                        closeModal={clearOrderUpload}>

                        <Fragment>

                            <div className="flex mt-16">
                                <p> There are issues with the following part numbers. Please select an action for each part number. </p>
                            </div>
                            <div className="table mt-24">
                                <div className="table__header">
                                    <FormInput
                                        type='text'
                                        placeholder='Enter part number'
                                        error={""}
                                        value={search}
                                        onChange={ handleFilterPartNumbers }
                                    />
                                </div>
                                <table className={"table__table"}>
                                    <thead className="table__thead">
                                        <tr className="table__tr">
                                            <th className="table__th">Part Number</th>
                                            <th className="table__th">Issues</th>
                                            <th className="table__th">Accept</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table__tbody">
                                    {
                                        currentIssueList.map((item, i) => {
                                            return (
                                                item.error && 
                                                <tr key={i} className="table__tr">
                                                    <td className="table__td">{ item.part_number ?? '' }</td>
                                                    <td className="table__td">
                                                        { 
                                                            ( item?.error && errors[item.error] && item.error === 'quantity' ) ? errors[item.error](item.quantity, item.asked_quantity) : errors[item.error]
                                                        }
                                                    </td>
                                                    <td className="table__td">
                                                        {
                                                            ( item?.error &&  item?.error === 'quantity' && item.quantity > 0 ) &&  
                                                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                    <CustomCheckBox
                                                                        checked={ item.approved ?? false }
                                                                        name={ item.part_number }
                                                                        onChange={ handleApproveChange.bind(this, item.part_number) }
                                                                    />
                                                                </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                totalRecords={filteredIssueList.length}
                                pageLimit={4}
                                pageNeighbours={1}
                                onPageChanged={onPageChanged}
                            />
                        </Fragment>

                        <div className="modal__actions">
                            <CustomButton type={"lightgray"} title={"Cancel"} onClick={clearOrderUpload} />

                            <CustomButton type={"blue"} title={"Continue"} margins={['ml-8']} onClick={makeOrder} />
                        </div>
                    </Modal>                
            }
        </Fragment>
    )
}

const mapDispatchToProps = {
    setCart: setCart,
};

export default connect(
    null,
    mapDispatchToProps
)(OrderUpload);
// export default OrderUpload;