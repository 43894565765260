import React from "react";
import Layout from "account/Orders/ReturnForm/Components/Layout";
import _ from "lodash";
import "account/Orders/ReturnForm/Forms/ReturnFormPDF.css";

const ReturnFormPDF =  React.forwardRef((props, ref) => {
    const tableRowCount = 7; 
    let date = new Date().toLocaleString({timeZone: 'Europe/London'}, {
        month:"2-digit", day: "numeric", year: "numeric", hour: "2-digit", minute: "2-digit"
    });

    return (
        <Layout ref={ref}>
            <div className={`pdf-section`}>
                <div className="pdf-card">
                    <div className="pdf-mg-bottom-25">{date}</div>

                    <div className="pdf-card-top pdf-mg-bottom-25">
                        <div className="pdf-flex-centred pdf-relative">
                            <p className="pdf-card-badge">Agreed</p>
                            <h3>RETURN</h3>
                        </div>
                        <p>( Tour return delivery Nr.erhalten In the call center on 03691 257 100 )</p>
                    </div>
                    <div className="pdf-card-body">
                        <div className="pdf-form-input"><p className="pdf-font-size-sm">( tick the appropriate! )</p></div>
                        <div className="pdf-form-input pdf-flex"><p>1. Night express</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                        <div className="pdf-form-input pdf-flex"><p>2. Carrier</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                        <div className="pdf-form-input pdf-flex"><p>3. Own choice (ie. UPS/TNT, Postal service)</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                    </div>
                    <div className="pdf-card-footer pdf-flex">
                        <h4>Recipient:</h4>
                        <ul>
                            <li>SBS</li>
                            <li>Deutschland GmbH</li>
                            <li>Abteilung Logistik</li>
                            <li>Eichrodter Weg 57</li>
                            <li>99817 Eisenach</li>
                        </ul>
                    </div>
                </div>
                <div className="pdf-card">
                    <p className="pdf-mg-bottom-25">Rucklieferung</p>
                    <div className="pdf-flex">
                        <div className="pdf-card-left">
                            <div className="pdf-form-input pdf-flex"><p className="pdf-bold">Reference</p> <input type="text"/> </div>
                            <div className="pdf-form-input pdf-flex"><p className="pdf-bold">Date</p> <input type="text" value={date}/> </div>
                            <div className="pdf-form-input pdf-flex"><p className="pdf-bold">Customer number</p> <input type="text" value={props.account?.account_number}/> </div>
                        </div>
                        <div className="pdf-card-right">
                            <img src="https://nk.availcat.com/static/media/nk-logo.762d9ec0.png" width={123} height={80}></img>
                        </div>
                    </div>
                    <div className="pdf-card-address-wrapper">
                        <h5>Dispatched by/ Company stamp</h5>
                        <div className="pdf-card-address-body">
                            {/* <p>ARC GROUP</p> */}
                            <p>{props.account?.address_1}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pdf-section pdf-mg-top">
                <div className="pdf-card">
                    <table className="pdf-table">
                        <tr>
                            <th style={{width:"15%"}}>Code</th>
                            <th style={{width:"15%"}}>Qty</th>
                            <th style={{width:"35%"}}>Item reference</th>
                            <th style={{width:"35%"}}>Number of packages</th>
                        </tr>
                        {
                            _.times(tableRowCount, () => {
                                return (
                                    <tr>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                    </tr>
                                );
                            })
                        }
                    </table>
                </div>
                <div className="pdf-card">
                    <table className="pdf-table">
                        <tr>
                            <th style={{width:"15%"}}>Code</th>
                            <th style={{width:"15%"}}>Qty</th>
                            <th style={{width:"35%"}}>Item reference</th>
                            <th style={{width:"35%"}}>Number of packages</th>
                        </tr>
                        {
                            _.times(tableRowCount, () => {
                                return (
                                    <tr>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                    </tr>
                                );
                            })
                        }
                    </table>
                </div>
            </div>

            <div className="pdf-section pdf-mg-top">
                <div className="pdf-card">
                    <p>Reason for return</p>
                    <h4>Code Schlussel</h4>

                    <div className="pdf-list-warpper">
                        <div><b>01 Product return</b></div>
                            <div className="pdf-sub-ordered-list">
                                <ol>
                                    <li>011 <b>Catalogign error</b></li>
                                    <li>012 <b>Model change</b></li>
                                    <li>013 <b>OE correct  - NK partno. Wrong</b></li>
                                    <li>014 <b>Wrong cartype</b></li>
                                    <li>015 <b>TecDoc error</b></li>
                                    <li>016 <b>Item swapped</b></li>
                                </ol>
                            </div>
                            
                        <div><b>02 Wrong box content</b></div>
                        <div><b>03 Wrong item ordered by customer</b></div>
                        <div><b>04 Is not needed</b></div>
                        <div><b>05 Wrong delivery</b></div>
                        <div><b>06 Transport damage</b></div>
                        <div><b>07 Too late delivered</b></div>
                        <div><b>08 NOX</b></div>
                        <div><b>09 Glued label</b></div>
                        <div><b>10 Items to choose</b></div>
                    </div>

                    <div className="pdf-reason-desc">
                        <p>Stock return must be agreed upon before return!</p>
                        <p>Cores must be returned in its original box</p>
                        <p>For returns without signing the shipper shall bear the costs!</p>
                    </div>

                    <a href="https://www.sbs-shop.de/formulare.php" className="pdf-site-link">https://www.sbs-shop.de/formulare.php</a>
                </div>
                <div className="pdf-card">
                    <div className="pdf-form-input"><p><b>Reference</b></p> <input type="text"/> </div>
                    <div className="pdf-form-input"><p><b>Date</b></p> <input type="text"/> Kilo </div>
                    <h4 className="pdf-mg-top">Returnprocessing at SBS Automotive A/S</h4>
                    <div className="pdf-returnprocessing-wrapper pdf-mg-top">
                            <div className="pdf-form-input pdf-flex"><p>Shipment recieved on:</p> <div className="pdf-fake-input"></div></div>
                            <div className="pdf-form-input pdf-flex"><p>Processed on</p> <div className="pdf-fake-input"></div> </div>
                            <div className="pdf-form-input pdf-flex"><p>Processed on by</p> <div className="pdf-fake-input"></div> </div>

                            <div className="pdf-form-input pdf-flex"><p>For product control</p> <div className="pdf-fake-checkbox"></div> </div>
                            <div className="pdf-form-input pdf-flex"><p>Credit</p> <div className="pdf-fake-checkbox"></div> </div>
                            <div className="pdf-form-input pdf-flex"><p>Surcharge</p> <div className="pdf-fake-checkbox"></div> </div>
                            <div className="pdf-form-input pdf-flex"><p>Scrapped</p> <div className="pdf-fake-checkbox"></div> </div>
                            <div className="pdf-form-input pdf-flex"><p>Return to customer</p> <div className="pdf-fake-checkbox"></div> </div>
                            <div className="pdf-form-input pdf-flex"><p>Finished processing</p> <div className="pdf-fake-checkbox"></div> </div>

                            <div className="pdf-form-input pdf-flex"><p>Date</p> <span className="pdf-underline"></span> </div>
                            <div className="pdf-form-input pdf-flex"><p>Employee</p> <span className="pdf-underline"></span> </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
});

export default ReturnFormPDF;