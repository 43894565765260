import React, { useState } from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter, BrowserRouter as NavLink} from "react-router-dom";
import UserAddress from "admin/administrator/UserAddress.js";
import ChangeQuantity from "admin/administrator/ChangeQuantity/ChangeQuantity.js";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import { removeProduct, cleanCart, updateOutstandingOrdersQty, increaseQuantity, decreaseQuantity } from "redux/actions/shoppingCart";
import NoImage from "images/no-img.png";
import CustomAdditionalNotes from "admin/administrator/CustomAdditionalNotes.js";
import CustomProductPrices from "admin/administrator/CustomProductPrices.js";
import CustomButton from "admin/administrator/CustomButton.js";
import OrderUpload from "components/OrderUpload.js";
import alertify from "alertifyjs";
import axios from "axios1";
import _ from "lodash";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import { Fragment } from "react";
import CustomDelete from "admin/administrator/CustomDelete";
import Notify from "components/Notify";

const CheckoutContent = ({ settings, shoppingCart, user, history }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [warningMsg, setWarningMsg] = useState('');
    const [notes, setNotes] = useState("");
    const account = accountAdresses();
    const forComponents = initDateForCustomTable()
    const totalSum = calculateTotalSum();
    const initColumns = React.useMemo(
        () => [
        {
            Header: "Part Number",
            accessor: "part_number",
            className: "textLeft",
            isVisible: 1,
            Cell: attr => <input type={"text"}
                    className={"copy-partnumber copy-partnumber-" + attr.value}
                    readOnly
                    onClick={ event => {
                            let text = document.querySelector('.copy-partnumber-' + event.target.value);
                            text.select();
                            document.execCommand('copy');                        
                    }}
                    value={attr.value}
                />            
        },
        {
            Header: "Image",
            accessor: "images",
            className: "textCenter",
            isVisible: settings.images_in_shopping_cart,
            Cell: attr => (
                <div className="custom-table-image">
                    <img width="54px" 
                         src={ attr.value[0].url }
                         onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = NoImage
                        }}/>
                </div>
            )
        },
        {
            Header: "Lookup",
            accessor: "lookup",
            className: "textLeft",
            isVisible: 1,
            Cell: attr => attr.value.toUpperCase()
        },
        {
            Header: "Description",
            accessor: "section",
            className: "textLeft",
            isVisible: 1,
            Cell: ({value, row}) => (
                <Fragment>
                    <div>{value}</div>
                    {
                        row.original.description ?
                            <div className="mt-4">{row.original.description}</div> : null
                    }
                </Fragment>
            )
        },
        {
            Header: "Location",
            accessor: "account",
            className: "textLeft",
            isVisible: 1,
        },
        {
            id: "unit-price",
            Header: "Unit Price",
            accessor: "price",
            className: "textRight",
            isVisible: settings.show_prices,
            Cell: ({value, row}) => <div>{ parseFloat( value ).toFixed(2) }</div>
        },
        {
            id: "total-price",
            Header: "Total Price",
            accessor: "price",
            className: "textRight",
            isVisible: settings.show_prices,
            Cell: ({value, row}) => <div>{ (Math.round(1000 * (parseFloat( value ) * row.original.quantity)) / 1000).toFixed(2)}</div>
        },  
        {
            Header: "Quantity",
            accessor: "quantity",
            className: "textCenter",
            isVisible: 1,
            Cell: ({ row }) => (
                <ChangeQuantity product={_.find(shoppingCart, {account_id: row.original.account_id, id: row.original.id})}
                                handleIncreaseQuantity={(original, qty) => dispatch(increaseQuantity(original, qty))}
                                handleDecreaseQuantity={(original, qty) => dispatch(decreaseQuantity(original, qty))}/>
            ) 
        },
        {
            Header: "Action",
            accessor: "id",
            className: "textCenter",
            isVisible: 1,
            Cell: ({ row }) => (
                <CustomDelete
                    key={row.original.id}
                    onClick={() => dispatch(removeProduct(row.original))}
                />
            )
        }
    ], [shoppingCart]);

    function accountAdresses() {
        let account = user.user ? {...user.user.account} : null;
        return {
            address1: account ? account.address_1 : '',
            address2: account ? account.address_2 : '',
            town: account ? account.town : '',
            postal_code: account ? account.postal_code : ''
        }
    }

    function submitOrder() {
        let additional_notes = notes;
        setLoading(true);
        axios.post("/catalogue/orders", {
            shoppingCart: shoppingCart,
            additional_notes: additional_notes,
        }).then((response) => {
            setLoading(false);
            if(response.data.success && response.data?.type && response.data.type === 'outStock') {
                setShowWarning(true);
                setWarningMsg(response.data.msg);
                return;
            }
            if (response.data.success) {
                dispatch(cleanCart());
                dispatch(updateOutstandingOrdersQty(response.data.count, user.user.account));
                history.push(`/search`);
            }
            alertify.success(response.data.msg);
        });
    }

    function updateAdditionalNotesValue(value) {
        setNotes(value);
    }

    function initDateForCustomTable() {
        var data = [];
        if (shoppingCart.length > 0) {
            _.map( { ...shoppingCart } , function (param) {
                data.push({
                    id: param.product.id,
                    account: param?.cart?.account?.name || param.account,
                    account_id: param?.cart?.account_id || param.account_id, 
                    price: param.product.price,
                    part_number: param.product.part_number.part_number,
                    lookup: param.lookup,
                    images: '',
                    section: '',
                    images: param.product.part_number.allImages,
                    section: param.product.part_number.section.name,
                    quantity: param.quantity,
                    description: param.description,
                    symbol: (param.product.currency ? param.product.currency.symbol : param.product.symbol),
                    total: param.quantity * param.product.price
                });
            });
        }
        return data;
    }

    function calculateTotalSum() {
        let total = 0;
        forComponents.map((item) => {
            total += item.total;
        });
        return total.toFixed(2);
    }

    return (
        <div className="container">
            <Notify type={"danger"}
                visible={showWarning}
                close={setShowWarning}
                primaryText={"An error has occurred and we apologise for the inconvenience."}
                secondaryText={warningMsg}
                actions={[
                    {
                        name: "close",
                        action: setShowWarning,
                        type: "red"
                    }
            ]}/>
            <Loading loading={loading} />
            {
                shoppingCart.length > 0 ?
                    <Fragment>
                        <div className={"flex"}>
                            <UserAddress data={{title: user.user ? user.user.account.name : null, info: account}}/>
                            <OrderUpload 
                                setLoading={setLoading}
                                setShowWarning={setShowWarning} 
                                setWarningMsg={setWarningMsg}
                            />
                        </div>
                        <CustomReactTable columns={initColumns} data={forComponents} />
                        <div className={"flex flex-align-start mt-24"}>
                            <CustomAdditionalNotes updateAdditionalNotesValue={updateAdditionalNotesValue}/>
                            {
                                settings.show_prices ? <CustomProductPrices total={totalSum}/> : null
                            }
                        </div>
                        <div className={"flex flex-justify-end mt-24"}>
                            <CustomButton title={"Back to Catalogue"} type={"gray"} onClick={() => history.push('/Search')}/>
                            <CustomButton title={"Place Order"} type={"green"} onClick={submitOrder} margins={['ml-8']}/>
                        </div>
                    </Fragment>
                    :
                    <div className="empty-page">
                        <h1>Basket is empty</h1>
                        <CustomButton title={"Back to Catalogue"} type={"gray"} onClick={() => history.push('/Search')}/>
                    </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    shoppingCart: state.shoppingCart,
    settings: state.settings,
    user: state.auth
})


export default withRouter(connect(mapStateToProps)(CheckoutContent));